
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import ComposeFormLine from '@/components/shared/compose/ComposeFormLine.vue'
import MessageEditor from '@/components/shared/compose/MessageEditor.vue'

export default defineComponent({
  name: 'QuestionSurveyModal',
  components: {
    MessageEditor,
    ComposeFormLine,
    TmFormLine,
    TmButton,
    TmModal,
  },
  props: {
    label: {
      type: String,
    },
  },
  setup(props) {
    const labelValue = ref(props.label)

    return {
      labelValue,
    }
  },
})
