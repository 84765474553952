
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

type Size = 'small' | 'medium'

export default defineComponent({
  name: 'ComposeFormLine',
  props: {
    label: {
      type: String,
    },
    labelLineHeight: {
      type: Number,
    },
    leftLabel: {
      type: Boolean,
      default: true,
    },
    bottomBorder: {
      type: Boolean,
    },
    size: {
      type: String as PropType<Size>,
      default: 'medium',
    },
  },
})
