import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["compose-form-line", { 'compose-form-line--bottom-border': _ctx.bottomBorder }])
  }, [
    _createElementVNode("h6", {
      class: _normalizeClass(["compose-form-line__label", {
        'compose-form-line__label--left': _ctx.leftLabel,
        [`compose-form-line__label--${_ctx.size}`]: _ctx.size,
        [`compose-form-line__label--custom-line-height lh-${_ctx.labelLineHeight}`]: _ctx.labelLineHeight,
      }])
    }, _toDisplayString(_ctx.label), 3),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 2))
}